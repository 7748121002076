import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="editor">
          <textarea placeholder="Start typing...">

          </textarea>
        </div>
      </header>
    </div>
  );
}

export default App;
